import React from 'react';
import ErrorMessages from 'src/components/designSystems/Form/ErrorMessages';
import Label from 'src/components/designSystems/Form/Label';

type Props = {
  name?: string;
  label?: string;
  id?: string;
  describedby?: string;
  size?: 'small' | 'large';
  compact?: boolean;
  hidden?: boolean;
  error?: Record<string, any>;
  labelHidden?: boolean;
  children: JSX.Element | JSX.Element[];
  className?: string;
};

const Field: React.FC<Props> = ({
  name,
  label,
  id,
  describedby = '',
  compact,
  size = 'small',
  hidden,
  error,
  labelHidden,
  children,
  className,
}) => {
  const disabled = Array.isArray(children) ? false : !!children.props.disabled;

  return (
    <div
      className={`
        form-element
        form-element_${size}
        ${compact ? 'no-top-padding' : ''}
        ${error ? 'has-error' : ''}
        ${disabled ? 'disabled' : ''}
      `}
      style={{ display: hidden ? 'none' : 'block' }}
      data-cy={`input-${name}`}
    >
      {label && <Label title={label} id={id} className={`${className} ${labelHidden ? 'visibly-hidden' : ''}`} />}
      {children}
      <ErrorMessages error={error} fieldLabel={label} describedby={describedby} />
    </div>
  );
};

export default Field;

import React, { useEffect, useState } from 'react';
import { Controller, useForm, UseFormMethods } from 'react-hook-form';
import { Control, FieldErrors, Validate } from 'react-hook-form/dist/types/form';
import { useLocalStorage } from 'react-use';
import { Button } from '@hagerty/react-components';

import { isAgent } from 'src/api/client';
import { useAuthContext } from 'src/components/AuthProvider';
import { Checkbox, DateInput, PhoneInput, RadioButtonGroup } from 'src/components/designSystems';
import Field from 'src/components/designSystems/Form/Field';
import { Translate } from 'src/components/Translate';
import AddressFields from 'src/components/AddressFields';
import { useShelbyCustomer } from 'src/hooks/useShelbyCustomer';
import { useFreeTrial } from 'src/hooks/useFreeTrial';
import { usePurchaseFlowOptimization } from 'src/hooks/usePurchaseFlowOptimization';

import LoadingContainer from 'src/components/LoadingContainer';
import { FreeTrialWidget } from './molecule';
import { MembershipWidget } from 'src/features/checkout-optimization/membership-widget';
import { validateEmail } from 'src/helpers/validate-email';
import { EMAIL_PATTERN, EMAIL_INVALID_RESOURCE, EMAIL_REQUIRE_CLIENT_RESOURCE } from 'src/constants/email';

const i18n = {
  title: 'checkout.information.title',
  newPurchaseFlowTitle: 'checkout.information.newPurchaseFlow.title',
  desc: 'checkout.information.desc',
  newPurchaseFlowDesc: 'checkout.information.newPurchaseFlow.desc',
  profileTitle: 'checkout.information.newPurchaseFlow.profileTitle',
  anonUserDesc: 'checkout.information.newPurchaseFlow.anonUserDesc',
  paragraph: 'checkout.information.paragraph',
  agentParagraph: 'checkout.information.agentParagraph',
  tipSingleTier: 'checkout.information.tip.singleTier',
  agentTip: 'checkout.information.agentTip',
  genericError: 'generic.error.message',
  continue: 'profile.mailingAddressForm.button.text',
  checkboxLabel: 'checkout.form.checkbox.label',
  phone: 'address.changeForm.fields.phone.optional',
  birthdate: 'address.changeForm.fields.birthdate.format',
  dobDateError: 'address.changeForm.fields.dob.date.errors',
  dobFormatError: 'address.changeForm.fields.dob.errors',
  phoneNumberFormatError: 'address.changeForm.fields.phoneNumber.formatError',
  error: 'generic.error.message',
};

export type ContractFormData = {
  firstName: string;
  lastName: string;
  birthdate?: string; // MM-dd-yyyy
  addressLine1: string;
  addressLine2?: string;
  postalCode?: string;
  attn?: string;

  city: string;
  state: string;
  phonePreferred: string;
  phoneHome: string;
  phoneMobile: string;
  phoneType: 'Home' | 'Mobile';
  email: string;
  hasDateOfBirthField?: boolean;
};

type BirthdateProps = {
  control: Control<Record<string, any>>;
  errors?: FieldErrors<ContractFormData>;
  disabled?: boolean;
  hasDateOfBirthField?: boolean;
};

const validBirthdate = (birthdate: string): true | string => {
  if (birthdate && !birthdate.search(/[YMD]/)) {
    const date = new Date(birthdate);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return date < today || i18n.dobDateError;
  }
};

export const Birthdate: React.FC<BirthdateProps> = ({ control, errors, disabled, hasDateOfBirthField }) => {
  const className = !hasDateOfBirthField ? 'display-none' : '';

  return (
    <div className="col xs-size_12-of-12 lg-size_12-of-12">
      <Field
        name="birthdate"
        label={i18n.birthdate}
        error={errors?.birthdate}
        id="birthdate"
        describedby="birthdateDescribedby"
        className={className}
      >
        <Controller
          name="birthdate"
          control={control}
          rules={{
            required: hasDateOfBirthField,
            pattern: hasDateOfBirthField
              ? {
                  value: /^\d{2}\/\d{2}\/\d*$/i,
                  message: i18n.dobFormatError,
                }
              : undefined,
            validate: validBirthdate,
          }}
          render={({ onChange, value }) => (
            <DateInput
              name="birthdate"
              onChange={onChange}
              value={value}
              disabled={disabled}
              id="birthdate"
              className={className}
            />
          )}
        />
      </Field>
    </div>
  );
};

const phoneOptions = [
  { label: 'phone.home', value: 'Home' },
  { label: 'phone.mobile', value: 'Mobile' },
];

type Props = {
  register: UseFormMethods<ContractFormData>['register'];
  watch: UseFormMethods<ContractFormData>['watch'];
  control: UseFormMethods<ContractFormData>['control'];
  errors?: FieldErrors<ContractFormData>;
};

export const PhoneFields: React.FC<Props> = ({ register, watch, control, errors }) => {
  const typ: 'phoneHome' | 'phoneMobile' = watch('phoneType', 'Home') === 'Home' ? 'phoneHome' : 'phoneMobile';

  return (
    <>
      <div className="col xs-size_12-of-12">
        <RadioButtonGroup
          name="phoneType"
          reference={register}
          errors={errors}
          label={i18n.phone}
          options={phoneOptions}
          direction="horizontal"
          validation={{ required: true }}
          labelledby="phoneLabelledby"
          labelGroup
        />
      </div>
      <div className="col xs-size_12-of-12">
        <Field
          name={typ}
          error={errors[typ]}
          id="phone_number"
          compact
          labelHidden={true}
          label={i18n.phone}
          describedby="phoneDescribedby"
        >
          <Controller
            name={typ}
            control={control}
            rules={{
              required: false,
              pattern: {
                value: /^\d{10}$/,
                message: i18n.phoneNumberFormatError,
              },
            }}
            render={({ value, onChange }) => (
              <PhoneInput id="phone_number" name={typ} value={value} onChange={onChange} />
            )}
          />
        </Field>
      </div>
    </>
  );
};

type AddressSectionHeaderProps = {
  hasNewPurchaseFlow: boolean;
};

const AddressSectionHeader: React.FC<AddressSectionHeaderProps> = ({ hasNewPurchaseFlow }) => {
  return (
    <>
      <Translate
        as={hasNewPurchaseFlow ? 'h6' : 'h3'}
        className={hasNewPurchaseFlow && 'text-heading_7'}
        resourceKey={i18n.desc}
      />
      {isAgent ? (
        <p className="color-dark-2 max-width_content">
          <Translate resourceKey={i18n.agentParagraph} useHtml />
          <Translate resourceKey={i18n.agentTip} />
        </p>
      ) : hasNewPurchaseFlow ? (
        <Translate as="p" className="text_3 color-dark-1" resourceKey={i18n.newPurchaseFlowDesc} />
      ) : (
        <p className="color-dark-2 max-width_content">
          <Translate resourceKey={i18n.paragraph} />
          <Translate className="tip" resourceKey={i18n.tipSingleTier} useHtml />
        </p>
      )}
      <div className={hasNewPurchaseFlow ? 'inset-s' : 'inset-m'} />
    </>
  );
};

type TermsAndConditionsProps = {
  register: UseFormMethods<ContractFormData>['register'];
  errors: FieldErrors<ContractFormData>;
};
const TermsAndConditions: React.FC<TermsAndConditionsProps> = ({ register, errors }) => (
  <div className="col xs-size_12-of-12 lg-size_12-of-12">
    <div className="checkbox__container">
      <Checkbox
        name="agreeTerms"
        reference={register({ required: true })}
        error={errors['agreeTerms']}
        labelledby="termsLabelledby"
      >
        <Translate
          id="termsLabelledby"
          resourceKey={i18n.checkboxLabel}
          values={{
            privacyPolicy: 'https://www.hagerty.com/corporate/privacy-policy',
            termsOfUse: 'https://www.hagerty.com/corporate/legal',
            roadsideService: 'https://www.crosscountrymotorclub.com/hgty-18/',
          }}
          useHtml
        />
      </Checkbox>
    </div>
  </div>
);

type ButtonProps = {
  disabled: boolean;
};

const SubmitButton: React.FC<ButtonProps> = ({ disabled }) => (
  <>
    <div className="inset-s" />
    <Button
      name="saveContactInfo"
      buttonType="Primary"
      buttonSize="Large"
      type="submit"
      testId="submit-user-information"
      disabled={disabled}
    >
      <Translate resourceKey={i18n.continue} />
    </Button>
  </>
);

type ErrorPorps = {
  error: string | string[];
};

const FromError: React.FC<ErrorPorps> = ({ error }) => {
  if (!error || error.length === 0) return null;
  return (
    <fieldset className="form-element__control has-error" data-cy="form-error">
      <div className="form-element__help">
        <Translate resourceKey={error} />
      </div>
    </fieldset>
  );
};

type ProfileFieldsProps = {
  register: UseFormMethods<ContractFormData>['register'];
  control: UseFormMethods<ContractFormData>['control'];
  errors: FieldErrors<ContractFormData>;
  withBirthdate?: boolean;
  validateEmail: Validate;
  hasNewPurchaseFlow: boolean;
  isNonMember: boolean;
  hasDateOfBirthField?: boolean;
};

const ProfileFields: React.FC<ProfileFieldsProps> = ({
  register,
  control,
  errors,
  withBirthdate,
  validateEmail,
  hasNewPurchaseFlow,
  isNonMember,
  hasDateOfBirthField,
}) => {
  return (
    <div className={hasNewPurchaseFlow ? 'grid grid-wrap gutters' : 'contact-information-form'}>
      <div className={`col xs-size_12-of-12 ${hasNewPurchaseFlow && isNonMember ? 'lg-size_6-of-12' : ''}`}>
        <Field name="firstName" label={'checkout.form.firstname.label'} error={errors.firstName} id="first_name">
          <input className="text-input" name="firstName" id="first_name" ref={register({ required: true })} />
        </Field>
      </div>
      <div className={`col xs-size_12-of-12 ${hasNewPurchaseFlow && isNonMember ? 'lg-size_6-of-12' : ''}`}>
        <Field name="lastName" label={'checkout.form.lastname.label'} error={errors.lastName} id="last_name">
          <input className="text-input" name="lastName" id="last_name" ref={register({ required: true })} />
        </Field>
      </div>
      {!hasNewPurchaseFlow && (
        <Birthdate
          control={control}
          errors={errors}
          disabled={!withBirthdate}
          hasDateOfBirthField={hasDateOfBirthField}
        />
      )}
      {!isNonMember && (
        <div className="col xs-size_12-of-12 gray-label-tip">
          <Controller
            name="email"
            control={control}
            rules={{
              required: true,
              pattern: {
                value: EMAIL_PATTERN,
                message: EMAIL_INVALID_RESOURCE,
              },
              validate: validateEmail,
            }}
            render={() => (
              <Field
                name="email"
                label={'checkout.form.email.label'}
                error={errors.email}
                data-cy="input-email"
                id="email"
              >
                <input className="text-input" id="email" name="email" ref={register()} />
              </Field>
            )}
          ></Controller>
        </div>
      )}
    </div>
  );
};

type FormProps = {
  defaultValues: ContractFormData;
  onSubmit: (data: ContractFormData) => Promise<true | string[] | FieldErrors<ContractFormData>>;
  hasDateOfBirthField?: boolean;
};

const ContactInformationForm: React.FC<FormProps> = ({ defaultValues, onSubmit, hasDateOfBirthField }) => {
  const {
    register,
    errors,
    formState: { isSubmitting },
    reset,
    watch,
    trigger,
    control,
    setError,
    handleSubmit,
  } = useForm({ mode: 'onChange', defaultValues });

  const { isStfrmCustomer, hasStfCustomerStandalonePurchase } = useShelbyCustomer();
  const { isEligibleForFreeTrial } = useFreeTrial();
  const { hasNewPurchaseFlow } = usePurchaseFlowOptimization();

  useEffect(() => {
    reset(defaultValues);
    if (defaultValues.city) {
      // Trigger validation for address fields (to reveal errors early)
      trigger(['addressLine1', 'addressLine2', 'city', 'state', 'postalCode']).then(() => undefined, console.error);
    }
  }, [defaultValues]);

  const [formError, setFormError] = useState<string[]>();
  const { brokers, isAuthenticated } = useAuthContext();
  const [flags] = useLocalStorage('@FLAGS', {});

  if (flags['DEBUG']) {
    console.log(defaultValues, watch());
  }

  const onSave = async (data: ContractFormData, event?: React.BaseSyntheticEvent) => {
    event?.preventDefault();
    setFormError(null);
    const result = await onSubmit(data);
    if (result === true) return;
    if (Array.isArray(result)) {
      setFormError(result);
      return;
    }
    for (const key of Object.keys(result)) {
      setError(key as keyof ContractFormData, result[key]);
    }
  };

  const handleValidateEmail = (value: string) =>
    validateEmail({
      email: value,
      agentEmail: brokers?.agentEmail,
      debug: flags['DEBUG'],
      message: EMAIL_REQUIRE_CLIENT_RESOURCE,
    });

  return (
    <form className="status-info" onSubmit={handleSubmit(onSave)}>
      <Translate
        as="h2"
        className={hasNewPurchaseFlow ? 'text-heading_2' : 'text-display_2'}
        resourceKey={hasNewPurchaseFlow ? i18n.newPurchaseFlowTitle : i18n.title}
      />

      {hasNewPurchaseFlow && (
        <>
          {!isAuthenticated && <Translate as="p" className="text-lead_3" resourceKey={i18n.anonUserDesc} />}
          <div className="inset-s" />
          <div className="display-laptop-none">
            <MembershipWidget />
          </div>
          <Translate as="h3" className="text-heading_7" resourceKey={i18n.profileTitle} />
          <div className="inset-s" />
        </>
      )}
      <div className="grid grid-wrap gutters_direct ">
        <div className="col xs-size_1-of-1 max-width_content">
          <fieldset id="profile-group">
            <div className={hasNewPurchaseFlow ? '' : 'grid grid-wrap gutters'}>
              {(isAgent || hasNewPurchaseFlow) && (
                <ProfileFields
                  register={register}
                  control={control}
                  errors={errors}
                  withBirthdate={!defaultValues.birthdate}
                  validateEmail={handleValidateEmail}
                  hasNewPurchaseFlow={hasNewPurchaseFlow}
                  isNonMember={isAuthenticated && !isAgent}
                  hasDateOfBirthField={hasDateOfBirthField}
                />
              )}
            </div>
          </fieldset>
        </div>
      </div>
      {isEligibleForFreeTrial && !hasNewPurchaseFlow && (
        <div className="display-laptop-none">
          <FreeTrialWidget />
        </div>
      )}
      <AddressSectionHeader hasNewPurchaseFlow={hasNewPurchaseFlow} />
      <div className="grid grid-wrap gutters_direct">
        <div className="col xs-size_1-of-1 max-width_content">
          <fieldset id="address-group">
            <div className="grid grid-wrap gutters">
              <AddressFields register={register} errors={errors} hasNewPurchaseFlow={hasNewPurchaseFlow} />
              <PhoneFields control={control} register={register} watch={watch} errors={errors} />
              {!isAgent && (
                <>
                  <Birthdate
                    control={control}
                    errors={errors}
                    disabled={!!defaultValues.birthdate}
                    hasDateOfBirthField={hasDateOfBirthField}
                  />
                  {!hasNewPurchaseFlow && <TermsAndConditions register={register} errors={errors} />}
                </>
              )}
            </div>
          </fieldset>
          {isSubmitting && <LoadingContainer height={40} />}
          <SubmitButton disabled={isSubmitting || (isStfrmCustomer && !hasStfCustomerStandalonePurchase)} />
          <FromError error={formError} />
        </div>
      </div>
    </form>
  );
};

export default ContactInformationForm;

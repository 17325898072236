import { useEffect } from 'react';

import { useAuthContext } from 'src/components/AuthProvider';
import { getUserFromLocalStorage } from 'src/helpers/setHeaders';

const useMembershipStarted = () => {
  const { isAuthenticated } = useAuthContext();
  const user = getUserFromLocalStorage();

  useEffect(() => {
    if (isAuthenticated) {
      fetch(`${process.env.OMNI_URL}external/membership/started`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${user.access_token}`,
          'X-Access-Token': user.access_token,
        },
      });
    }
  }, [isAuthenticated]);
};

export { useMembershipStarted };

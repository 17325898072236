import React from 'react';
import { JoiMessage } from 'src/components/JoiMessage';
import { Translate } from 'src/components/Translate';

type Props = {
  error?: Record<string, any>;
  describedby?: string;
  fieldLabel: string;
};

const ErrorMessages: React.FC<Props> = ({ error, fieldLabel, describedby = '' }) => {
  if (!error) return null;

  return (
    <div className="form-element__help" id={describedby}>
      {error?.type === 'required' && (
        <>
          <Translate resourceKey={fieldLabel} />
          <span>{` is required`}</span>
        </>
      )}
      {error?.type === 'joi' && <JoiMessage message={error?.message} />}
      {error?.message && !['required', 'joi'].includes(error?.type) && <Translate resourceKey={error?.message} />}
    </div>
  );
};
export default ErrorMessages;

/**
 * Converts Joi errors to React Hook Form errors
 * @param {Array<object>} Joi error details array
 * @return {Array<{name: string, type: 'joi', message: string}>} React Hook Form errors array
 */
const mapJoiErrorsToRHF = (errors = []) => {
  return errors.map((f) => ({
    name: f.context.key,
    type: 'joi',
    message: f.message.replace(`"${f.context.key}"`, 'Value'),
  }));
};

export default mapJoiErrorsToRHF;

import React from 'react';
import Helmet from 'react-helmet';

import { Translate } from 'src/components/Translate';
import ShelbyFooter from 'src/components/organism/ShelbyFooter/ShelbyFooter';
import { useTracking } from 'src/components/TrackingContext';

import './ShelbyExistingUser.scss';

const metadata = {
  title: 'Existing Membership | HDC for State Farm',
};

const ShelbyExistingUser = () => {
  useTracking('sf_existing_member_view');

  return (
    <>
      <Helmet title={metadata.title} />
      <div className="container container_center" data-cy="shelby-existing-user">
        <div className="shelby-existing-user">
          <Translate as="h2" className="shelby-existing-user__title" resourceKey="checkout.agent.existing.user.title" />
          <Translate
            as="p"
            className="shelby-existing-user__description text-lead_2"
            resourceKey="checkout.agent.existing.user.description"
          />
          <div className="inset-m" />
          <Translate
            as="h4"
            className="shelby-existing-user__sub-title text-display_4"
            resourceKey="checkout.agent.existing.user.subtitle"
          />
          <Translate
            as="p"
            className="shelby-existing-user__assistance"
            resourceKey="checkout.agent.existing.user.assistance"
            useHtml
          />
          <Translate className="text-lead_3" resourceKey="checkout.agent.existing.user.exit" />

          <div className="inset-l" />
          <Translate className="disclaimer" resourceKey={'state-farm.disclaimer.footer'} />
        </div>

        <ShelbyFooter showDisclaimer={false} />
      </div>
    </>
  );
};

export default ShelbyExistingUser;

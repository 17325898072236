import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import ShelbyFooter from '../organism/ShelbyFooter/ShelbyFooter';
import { Translate } from '../Translate';
import { useTracking, useTrackingContext } from 'src/components/TrackingContext';

const metadata = {
  title: 'Non-Solicitation State | HDC for State Farm',
};

const LicensedState: React.FC = () => {
  const [callOnce, setCallOnce] = useState(false);
  const { trackView } = useTrackingContext();
  useTracking('sf_non_solicitation_state_view', null, true);

  useEffect(() => {
    if (trackView && !callOnce) {
      trackView('sf_non_solicitation_state_view', null, {
        virtual_page_name: 'cannot-offer-hdc',
      });
      setCallOnce(true);
    }
  }, [trackView]);

  return (
    <>
      <Helmet title={metadata.title} />
      <div className="container container_center" data-cy="licenced-state">
        <div className="status-info">
          <Translate as="h2" resourceKey={'checkout.noLicenceState.title'} />
          <Translate as="p" className="text-lead_2" resourceKey={'checkout.noLicenceState.text'} />
        </div>

        <div className="shelby-footer-separator">
          <ShelbyFooter />
        </div>
      </div>
    </>
  );
};

export default LicensedState;

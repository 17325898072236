import React from 'react';
import { UseFormMethods } from 'react-hook-form';
import { isAgent } from 'src/api/client';

import type { ContractFormData } from 'src/components/ContactInformationForm';
import { SelectInput } from 'src/components/designSystems';
import Field from 'src/components/designSystems/Form/Field';
import { States } from 'src/services/states-client';

const stateOptions = Object.keys(States).map((key) => ({ value: key, text: key }));

type Props = {
  register: UseFormMethods<ContractFormData>['register'];
  errors?: Record<string, any>;
  hasNewPurchaseFlow: boolean;
};
export default function AddressFields({ register, errors, hasNewPurchaseFlow }: Props) {
  const i18n = {
    attn: 'address.changeForm.fields.attn',
    street: 'address.changeForm.fields.street',
    street2: 'address.changeForm.fields.street2.optional',
    address: 'address.changeForm.fields.address',
    address2: 'address.changeForm.fields.address2',
    email: 'address.changeForm.fields.email',
    city: 'address.changeForm.fields.city',
    state: 'address.changeForm.fields.state',
    zip: 'address.changeForm.fields.zip',
    zipFormatError: 'address.changeForm.fields.zip.formatError',
  };

  const handleWhitespace = {
    required: (val) => {
      return !!val.trim();
    },
  };

  return (
    <>
      <div className="col xs-size_12-of-12">
        <Field
          name="addressLine1"
          id="address_line_1"
          label={hasNewPurchaseFlow ? i18n.address : i18n.street}
          error={errors.addressLine1}
          describedby="addressLine1Describedby"
        >
          <input
            className="text-input"
            name="addressLine1"
            id="address_line_1"
            ref={register({ validate: handleWhitespace })}
            disabled={isAgent}
            aria-describedby="addressLine1Describedby"
          />
        </Field>
      </div>
      <div className="col xs-size_12-of-12">
        <Field
          name="addressLine2"
          id="address_line_2"
          label={hasNewPurchaseFlow ? i18n.address2 : i18n.street2}
          error={errors.addressLine2}
          describedby="addressLine2Describedby"
        >
          <input
            className="text-input"
            name="addressLine2"
            id="address_line_2"
            ref={register({ required: false })}
            disabled={isAgent}
            aria-describedby="addressLine2Describedby"
          />
        </Field>
      </div>
      <div className="col xs-size_12-of-12 lg-size_6-of-12">
        <Field name="city" id="city" label={i18n.city} error={errors.city} describedby="cityDescribedby">
          <input
            className="text-input"
            id="city"
            name="city"
            ref={register({ validate: handleWhitespace })}
            disabled={isAgent}
            aria-describedby="cityDescribedby"
          />
        </Field>
      </div>
      <div className={`col xs-size_12-of-12 ${hasNewPurchaseFlow ? 'lg-size_6-of-12' : 'lg-size_3-of-12'}`}>
        {stateOptions.length > 0 && (
          <SelectInput
            name={'state'}
            id="state"
            reference={register}
            validation={{ required: true }}
            errors={errors}
            label={i18n.state}
            options={stateOptions}
            defaultOption={i18n.state}
            required
            disabled={isAgent}
          />
        )}
      </div>
      <div className={`col xs-size_12-of-12 ${hasNewPurchaseFlow ? 'lg-size_6-of-12' : 'lg-size_3-of-12'}`}>
        <Field
          name="postalCode"
          label={i18n.zip}
          error={errors.postalCode}
          id="zip_code"
          describedby="postalCodeDescribedby"
        >
          <input
            className="text-input"
            id="zip_code"
            name="postalCode"
            ref={register({
              required: true,
              pattern: { value: /^\d{5}((-)?\d{4})?$/, message: i18n.zipFormatError },
            })}
            disabled={isAgent}
            aria-describedby="postalCodeDescribedby"
          />
        </Field>
      </div>
    </>
  );
}

/**
 * Returns a contentful message key for the given error with the fallback for non-existent key
 * @param {string} method HTTP method in lowercase
 * @param {string} endpoint endpoint path separated by periods
 * @param {object} error object with status, code and error message
 *
 * @return {[string]}
 */
const getErrorMessage = (method, endpoint, error) => {
  const messageKey = `${method}.${endpoint}.${error.status ? error.status : error.statusCode}.error.code.${error.code}`;
  return [messageKey, 'error.unspecific'];
};

export default getErrorMessage;

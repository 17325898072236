import { useEffect } from 'react';

import { useFreeTrial } from './useFreeTrial';
import useSelectedLevel from './useSelectedLevel';

enum Level {
  FreeTrial = 4,
  SecondGear = 2,
}

/**
 * @name useMembershipLevel
 *
 * @description This hooks sets the `SelectedLevel` value in Local Storage
 * to ensure the correct membership level is purchased each time the user
 * visits the checkout flow.
 *
 * @returns level - 4 (Free Trial) OR 2 (Second Gear)
 */

const useMembershipLevel = (): Level => {
  const { isEligibleForFreeTrial, freeTrialIsReady } = useFreeTrial();
  const [level, setLevel] = useSelectedLevel(Level.SecondGear);

  useEffect(() => {
    if (freeTrialIsReady) {
      if (isEligibleForFreeTrial) setLevel(Level.FreeTrial);
      else setLevel(Level.SecondGear);
    }
  }, [freeTrialIsReady, isEligibleForFreeTrial]);

  return level;
};

export { useMembershipLevel };

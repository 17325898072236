import { isAgent } from 'src/api/client';

type Params = {
  email: string;
  agentEmail: string;
  message: string;
  debug?: boolean;
};

const validateEmail = ({ email, agentEmail, message, debug }: Params): Params['message'] | true => {
  if (isAgent) {
    if (debug) {
      console.log('Validate Email: ', email, 'brokers email=', agentEmail);
    }

    if (email.toLocaleLowerCase() === agentEmail.toLocaleLowerCase()) {
      return message;
    }
  }
  return true;
};

export { validateEmail };

import React from 'react';
import { Translate } from 'src/components/Translate';

type Props = {
  title: string;
  id?: string;
  className?: string;
};

const Label: React.FC<Props> = ({ title, id, className }) => {
  return <Translate as="label" resourceKey={title} className={`form-element__label ${className}`} htmlFor={id} />;
};

export default Label;

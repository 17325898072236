import { MutationHookOptions, useMutation } from '@apollo/client';
import format from 'date-fns/format';
import { isAgent } from 'src/api/client';
import { useAuthContext } from 'src/components/AuthProvider';

import BrokersUpdateProfileMutation from './graphql/brokers/mutations/UpdateProfileMutation.graphql';
import UsersUpdateProfileMutation from './graphql/users/mutations/UpdateProfileMutation.graphql';

export type UpdateProfileInput = {
  // username?: string;
  givenName?: string;
  familyName?: string;
  middleName?: string;

  birthdate?: string; // Birth date following ISO 8601.
  // gender?: string;

  // region?: string;
  country?: string;
  province?: string;
  city?: string;

  phoneMobile?: string;
  phoneHome: string;
  phonePreferred?: string;
  email?: string;

  // newsletterFrequency: string;
  // specialEditions: string[]; // List of special editions.
  // additionOptions: string[]; // List of additional options.

  address: {
    attn: string; // Attention for user.
    addressLine1: string;
    addressLine2: string;
    postalCode: string;
  };

  billingAddress?: {
    attn: string; // Attention for user.
    givenName: string; // Given name.
    middleName: string; // Middle name.
    familyName: string; // Family name.
    suffix: string; // Name suffix.

    title: string; // Title.
    recipientPhone: string; // Recipient phone number.

    country: string; // Country.
    province: string; // Province.
    city: string; // City.
    postalCode: string; // Postal code.
    addressLine1: string; // First address line.
    addressLine2: string; // Second address line.
  };
};

const useUpdateProfile = (data?: UpdateProfileInput) => {
  const { userId } = useAuthContext();
  if (data) {
    if (!data?.country) {
      data.country = 'US';
    }
    if (data?.birthdate) {
      // regex is used because safari doesnt accept 
      // The pattern yyyy-MM-dd isn't an officially supported format for Date constructor
      const date = new Date(data.birthdate.replace(/-/g, "/"));
      data.birthdate = format(date, 'y-M-d');
    }
  }
  const doc = isAgent ? BrokersUpdateProfileMutation : UsersUpdateProfileMutation;
  const options = isAgent ? { variables: { input: data, userId } } : { variables: { input: { data } } };
  const [updateProfile] = useMutation(doc, options as MutationHookOptions);
  return updateProfile;
};

export default useUpdateProfile;
